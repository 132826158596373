import { RouterProvider, createBrowserRouter, Link } from 'react-router-dom'
import './App.css';
import React, { useEffect } from 'react';
import { AppProvider } from './AppContext';
import { Home } from './pages/Home';
import { Frens } from './Components/Friends/Frens';
import { Tasks } from './Components/Tasks/Tasks';
import { TeamExplorer } from './Components/Team/TeamExplorer';
import { Team } from './Components/Team/Team';
import { Boost } from './Components/Boost/Boost';
import League from './Components/League/League';
import FriendsList from './Components/Friends/FriendsList';
import Loading from './Components/Loading/Loading';
import CreateTeam from './Components/Team/CreateTeam';
const router = createBrowserRouter([
  {
    path: '/',
    element: <Loading />
  },
  {
    path: '/home',
    element: <Home />
  },
  {
    path: '/frens',
    element: <Frens />
  },
  {
    path: '/earn/:tab',
    element: <Tasks />
  },
  {
    path: '/team-explorer',
    element: <TeamExplorer />
  },
  {
    path: '/team/:teamId',
    element: <Team />
  },
  {
    path: '/boosts',
    element: <Boost />
  },
  {
    path: "/league/:leagueId",
    element: <League />
  },
  {
    path: "/friendsList",
    element: <FriendsList />
  },
  {
    path: "/create_team",
    element: <CreateTeam />
  }
]);

function App() {

  return (
    <AppProvider>
      <RouterProvider router={router} />
    </AppProvider>
  );
}

export default App;