import React from 'react'
import qr from "./qr.jpg"

function PlayOnPhone() {
    return (
        <div className='PlayOnPhone'>
            <div className='text'>Play on phone</div>
            <img src={qr}></img>
            <div className='subtext'>@gemcashbot</div>
        </div>
    )
}

export default PlayOnPhone