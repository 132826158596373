import React, { useState } from 'react'
import { Link, useLocation } from 'react-router-dom';
import Friends from "./Friends.png"
import "./footer.css"
import Earn from "./Earn.png"
import task from "./Tasks.png"
import ComingSoon from '../ComingSoon/ComingSoon';
export default function Footer() {
    const location = useLocation();
    const [show, setShow] = useState(false)

    return (
        <>
            {show ? <ComingSoon onClick={() => setShow(false)} /> : ""}
            <div className="footer">
                <div className="menu">
                    <Link to="/earn/tasks" className="menu-button" >
                        <img src={Earn} alt="Earn" />
                        <span className="button-text">Earn</span>
                    </Link>
                    <div className="divider"></div>
                    <Link to="/frens" className="menu-button">
                        <img src={Friends} alt="Gift" />
                        <span className="button-text">Friends</span>
                    </Link>
                    <div className="divider"></div>
                    <Link onClick={() => setShow(true)} className="menu-button">
                        <img src={task} alt="Gift" />
                        <span className="button-text">DoosPad</span>
                    </Link>
                </div>
            </div>
        </>
    );
}