import React from 'react';
import "./loading.scss"
import Loading_block from './Loading_block'
import img1 from "./1.png"
import img2 from "./2.png"
import img3 from "./3.png"
import img5 from "./discord.png"
import img6 from "./x.jpg"
import img7 from "./youtube.png"
import { Link } from 'react-router-dom';
import website from "./website.svg"
function Loading() {


    const openLink = (url) => {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(url);
        } else {
            window.open(url, '_blank');
        }
    };
    const openTelegramLink = (url) => {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink(url);
        } else {
            window.open(url, '_blank');
        }
    };


    return (
        <div className='loading'>
            <div class="gradient-text"> Welcome to DoosCoin</div>

            <div className='loading_container'>
                <Loading_block
                    text="Tap to farm tokens "
                    subtext="Receive Coins by tapping the screen"
                    img={img1}
                />
                <Loading_block
                    text="Buy Meme coins"
                    subtext="Buy memes and get passive income  - even when you're not playing"
                    img={img2}
                />
                <Loading_block
                    text="Purchase Boosts"
                    subtext="Upgrade your level - increasing values of your income"
                    img={img3}
                />

            </div>

            <div className="btn_container_play">
                <button className="btn_play" onClick={() => openTelegramLink(' https://t.me/DoosCoin')}>
                    <img src='https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/other/telegram.png' alt="Telegram" />
                    Telegram
                </button>
                <button className="btn_play" onClick={() => openLink('http://www.dooscoin.com')}>
                    <img src={website} alt="Website" />
                    Website
                </button>
                <button className="btn_play" onClick={() => openLink('https://x.com/doos_coin')}>
                    <img src={img6} alt="Close" />
                    X
                </button>
                <button className="btn_play" onClick={() => openLink('https://www.instagram.com/doos_coin')}>
                    <img src={img5} alt="Close" />
                    Instagram
                </button>
            </div>
            <button className="btn_play" onClick={() => openLink('https://youtube.com/@dooscoin')}>
                <img src={img7} alt="Close" />
                Youtube
            </button>

            <div className='start_playing'>
                <Link to="/home" className="btn_play">Start Playing</Link>
            </div>
        </div>

    )
}

export default Loading