import React from 'react';
// import './shop.scss';
import niki from "./Niki.png"
import coin from "../../coin.png"
const PetCard = ({ name, logo, coins_per_hour, level, upgradeCost, onMemeClick }) => {
    return (
        <div className="petCard" onClick={onMemeClick}>
            <div className="iconWrapper">
                <img src={logo} alt={name} className="petIcon" />
            </div>
            <div className="infoWrapper">
                <h2 className="petName">{name}</h2>
                <div className="profitValue flex_center"><img src={coin} className="coin_icon_shop" alt="Coin-icon" /> +{coins_per_hour} /hr</div>
                <div className="levelInfo">
                    <span className="levelLabel">lvl {level}</span>
                    <span className="coins flex_center"> <img src={coin} className="coin_icon_shop" alt="Coin-icon" />{upgradeCost}</span>
                </div>
            </div>
        </div>
    );
};


export default PetCard;
