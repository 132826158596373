import React from 'react'
import "./comingsoon.scss"
import img from "./img1.png"
function ComingSoon({ onClick }) {
    return (
        <div className='outer_container_coming'>
            <span className="close" onClick={onClick}>&times;</span>

            <div className='container_glow_coming'>
                <div className='text_coming'>🔒 Сoming soon</div>
            </div>
        </div>
    )
}

export default ComingSoon