import React, { useState } from 'react';
import Back from '../Back';
import { createTeam } from '../../http';
import { useNavigate } from 'react-router-dom';
function CreateTeam() {
    const [teamName, setTeamName] = useState('');
    const [teamLink, setTeamLink] = useState('');
    const [teamLogo, setTeamLogo] = useState('');
    const navigate = useNavigate()
    const handleSubmit = async (event) => {
        event.preventDefault();

        await createTeam(teamName, teamLink, teamLogo);

        navigate(-1)

    };

    return (
        <div className='teams_header_container'>
            <Back />
            <div className='team_background'>
                <div className="header_create_team">
                    <div>
                        <div className="header_teams_background"></div>
                        <div className="header_container">
                            <h2 className="tab-title">Create your own team!</h2>
                            <div className="tab-subtitle">And win tournaments</div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='outer-container_team'>
                <div className='container_glow_team'>
                    <form onSubmit={handleSubmit}>
                        <div className='input_container'>
                            <label htmlFor="teamName">Team name</label>
                            <input
                                type="text"
                                className="form-control"
                                id="teamName"
                                value={teamName}
                                onChange={(e) => setTeamName(e.target.value)}
                                required
                            />
                        </div>
                        <div className='input_container'>
                            <label htmlFor="teamLink">Team link</label>
                            <input
                                type="text"
                                className="form-control"
                                id="teamLink"
                                value={teamLink}
                                onChange={(e) => setTeamLink(e.target.value)}
                                required
                            />
                        </div>
                        <div className='input_container'>
                            <label htmlFor="teamLogo">Team logo</label>
                            <input
                                type="text"
                                className="form-control"
                                id="teamLogo"
                                value={teamLogo}
                                onChange={(e) => setTeamLogo(e.target.value)}
                                required
                            />
                        </div>
                        <button type="submit" className="create_team_btn" >Create Team</button>
                    </form>
                </div>
            </div>
        </div>
    );
}

export default CreateTeam;
