import { completeTask } from '../../http';
import coinImg from '../../images/icons/coin.png';
import Back from '../Back';
import Task from './Task';
import "./tasks.scss";
import test from "./test.png";
import React, { useState, useEffect } from 'react';
import StatBlock from '../StatsMain/StatBlock';
import { useAppData } from '../../AppContext';
import PetCard from './PetCard';
import { useLocation } from 'react-router-dom';
import { getPlayerMemes, manageMeme, getUserTasks } from '../../http';
import "./shop.scss"
import coin from "../../coin.png"
export const Tasks = () => {

    const { coins, setpassiveIncomePerHour, memes, handleEditCoins } = useAppData();
    const [activeTab, setActiveTab] = useState('tasks');
    const [show, setShow] = useState(false);
    const [palyerMemes, setpalyerMemes] = useState(memes.memes);
    const [selectedMeme, setSelectedMeme] = useState(null);
    const location = useLocation();
    const { tasks, setTriggerRender } = useAppData();

    useEffect(() => {
        if (location.pathname.includes('/earn/tasks')) {
            setActiveTab('tasks');
        } else if (location.pathname.includes('/shop/niki')) {
            setActiveTab('niki');
        } else if (location.pathname.includes('/shop/craft')) {
            setActiveTab('craft');
        }
    }, [location]);


    const handleTabClick = (tabName) => {
        setActiveTab(tabName);
    };

    const [selectedTask, setSelectedTask] = useState(null);

    const handleTaskClick = (task) => {
        setSelectedTask(task);
    };


    const forceUpdate = () => setTriggerRender(prev => !prev);

    const handleMoadalTaskClick = async (link, id, task__coins_reward) => {
        handleEditCoins(task__coins_reward);
        await completeTask(id);
        handleCloseModal();
        forceUpdate();

        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openLink(link);
        } else {
            window.open(link, '_blank');
        }
    };

    const handleCloseModal = () => {
        setSelectedTask(null);
        setSelectedMeme(null);
    };

    const renderDescription = (description) => {
        const lines = description.split('+').filter(line => line.trim() !== '');
        return lines.map((line, index) => (
            <p key={index}>+ {line.trim()}</p>
        ));
    };

    const handleMemeClick = (memeItem) => {
        setSelectedMeme(memeItem);
    };

    const getPlayerMemesData = async () => {
        const data = await getPlayerMemes();
        console.log(data)
        setpalyerMemes(data.memes);
    }
    const handleManageMeme = async (memeId, coins_per_hour, upgrade_cost, upgraded_coins_per_hour) => {
        if (coins >= upgrade_cost) {
            await manageMeme(memeId);
            handleEditCoins(-upgrade_cost)
            setpassiveIncomePerHour(prev => prev + (upgraded_coins_per_hour - coins_per_hour));
            await getPlayerMemesData();

        }
        handleCloseModal();
    };
    return (
        <div className='outer-container_boost'>
            <div className='container_glow_shop'>


                <Back />

                <StatBlock />
                <div className='coins_container_shop'>
                    <div className='coins_text'>Your balance</div>
                    <div className="stats">
                        <div id="balance" className="coin-count flex_center"><img src={coin} className="coin_balance_shop" alt="Coin-icon" /> {coins.toLocaleString('en-US')}</div>
                    </div>
                </div>

                <div className="navigationTab">
                    <button className={`navButton ${activeTab === 'tasks' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('tasks')}>
                        <span>Tasks</span>
                    </button>

                    <button className={`navButton ${activeTab === 'income' ? 'selected' : ''}`}
                        onClick={() => handleTabClick('income')}>
                        <span>Income🫰</span>
                    </button>


                    <button disabled className={`navButton ${activeTab === 'craft' ? 'selected' : ''}`}
                        onClick={() => { setShow(true); setActiveTab("upgrade"); }}>
                        <span className='craft_container'>
                            <span>Extra Income</span>
                            <span className='coming_soon_text'>🔒 Add 3 friends</span>
                        </span>
                    </button>
                </div>

                <div className='shop_grid'>
                    {activeTab === 'tasks' && tasks.map((task) => (
                        <Task
                            key={task.id}
                            task={task.task__name}
                            icon={task.task__logo || test}
                            coins={`+${task.task__coins_reward}`}
                            status={task.status}
                            onClick={() => handleTaskClick(task)}
                        />
                    ))
                    }
                    {activeTab === 'income' && palyerMemes.map((meme) => (
                        <PetCard
                            key={meme.meme_id}
                            name={meme.name}
                            logo={meme.logo}
                            upgradeCost={meme.upgrade_cost}
                            coins_per_hour={meme.coins_per_hour}
                            level={meme.level}
                            onMemeClick={() => handleMemeClick(meme)}

                        />
                    ))}

                </div>

            </div>

            {selectedTask && (
                <div className="modal">
                    <div className="modal-container">
                        <span className="close" onClick={handleCloseModal}>&times;</span>
                        <div className="modal-content_tasks">
                            <img src={selectedTask.task__logo || test} className="modal-image" alt="Task icon" />
                            <h2 className='modal-title'>{selectedTask.task__name}</h2>
                            <div className='task__description'>
                                {renderDescription(selectedTask.task__description)}т

                            </div>
                            <p style={{ fontWeight: 'bold' }}>+ {selectedTask.task__coins_reward} <img src={coin} className="coin-icon" alt="Coin-icon" /></p>
                            <div className='penalty'>⚠ {selectedTask.task__penalty} </div>
                            <button className='button-open-task button-purple modal-button' onClick={() => handleMoadalTaskClick(selectedTask.task__link, selectedTask.id, selectedTask.task__coins_reward)}>Open task☝️</button>
                        </div>
                    </div>
                </div>
            )}


            {selectedMeme && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container">
                            <div className="modal-content_shop">
                                <span className="close" onClick={handleCloseModal}>&times;</span>
                                <img src={selectedMeme.logo} alt={selectedMeme.name} className="modal-image_shop" />
                                <h3>{selectedMeme.name}</h3>
                                {selectedMeme.is_owned == "true" ? (
                                    <div>
                                        <div className='text-modal'>You already own this meme.</div>
                                    </div>
                                ) : (
                                    <div style={{ width: "100%" }}>
                                        <div className='text-modal'></div>
                                        <div className='text-modal-level'>lvl {selectedMeme.level} {`->`} <span className='green'>lvl {selectedMeme.level + 1} </span>  </div>

                                        <div className='text-modal'>Profit per hour:</div>
                                        <div className='text-modal-profit'>
                                            <img
                                                src={coin}
                                                className="coin_icon_shop_modal"
                                                alt="Coin-icon" />

                                            {selectedMeme.coins_per_hour} {`->`} <span className='green'> +{selectedMeme.upgraded_coins_per_hour}</span>
                                            <img
                                                src={coin}
                                                className="coin_icon_shop_modal"
                                                alt="Coin-icon" />

                                        </div>

                                        <div className='coins_modal_shop'><img src={coin} className="coin_icon_shop_modal_total" alt="Coin-icon" />{selectedMeme.upgrade_cost} </div>
                                        <button className='button_upgrade' onClick={() => handleManageMeme(selectedMeme.meme_id, selectedMeme.coins_per_hour, selectedMeme.upgrade_cost, selectedMeme.upgraded_coins_per_hour)} >BUY</button>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};
