
const URL = "https://doosdoos.shop/";

// let USER_ID = "5928954497"; 
let USER_ID = null; 

if (window.Telegram && window.Telegram.WebApp && window.Telegram.WebApp.initDataUnsafe && window.Telegram.WebApp.initDataUnsafe.user) {
  window.Telegram.WebApp.ready();
  USER_ID = window.Telegram.WebApp.initDataUnsafe.user.id;
}

const fetchData = async (request) => {
  try {
    const response = await fetch(`${URL + request}`, {
      method: "get",
      headers: new Headers({
        "ngrok-skip-browser-warning": "69420",
      }),
    });
    if (!response.ok) {
      throw new Error("Network response was not ok");
    }
    const data = await response.json();
    console.log('data:', request, data);
    console.log(Date.now())
    return data;
  } catch (error) {
    return null;
  }
};

const sendData = async (request, data) => {
  try {
    const formattedRequest = request.endsWith('/') ? request : `${request}/`;
    const formData = new URLSearchParams(data).toString();
    const response = await fetch(`${URL}${formattedRequest}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
      body: formData,
    });
    const result = await response.json();
    console.log('post:', request, result);
    console.log(Date.now())
    return result;
  } catch (error) {
    console.error('Error fetching:', error);
    return null;
  }
};

export const sendCoinsAndEnergy = async (coins, energy) => {
  const response = await sendData("update_coins_and_energy", {user_id: USER_ID, coins_count: coins, energy_count: energy});
  return response;
};

export const sendUpgrade = async (upgradeId) => {
  const response = await sendData("buy_upgrade", {user_id: USER_ID, upgrade: upgradeId});
  return response;
};

export const sendSpecial = async (specialId) => {
  const response = await sendData("use_boost", {user_id: USER_ID, boost: specialId});
  return response;
};

export const sendJoinTeam = async (teamId) => {
  const response = await sendData("join_team", {user_id: USER_ID, team_id: teamId});
  return response;
};

export const sendLeaveTeam = async (teamId) => {
  const response = await sendData("leave_team", {user_id: USER_ID, team_id: teamId});
  return response;
};

// 1234567890

export const buyPet = async (pet_id, name) => {
  const response = await sendData("buy_pet", {user_id: USER_ID, pet_id: pet_id, name: name});
  return response;
};
export const feedPet = async () => {
  const response = await sendData("feed_pet", {user_id: USER_ID});
  return response;
};

export const buyFood = async (food_id) => {
  const response = await sendData("buy_food", {user_id: USER_ID, food_id:food_id});
  return response;
};

export const manageMeme = async (meme_id) => {
  const response = await sendData("manage_meme", {user_id: USER_ID, meme_id: meme_id});
  return response;
};

export const selectFood = async (food_id) => {
  const response = await sendData("select_food", {user_id: USER_ID, food_id:food_id});
  return response;
};
export const SendInvite = async () => {
  const response = await sendData("send_invite_message", {user_id: USER_ID});
  return response;
};
export const completeTask = async (task_id) => {
  const response = await sendData("complete_task", {task_id:task_id});
  return response;
};
export const createTeam = async (name, channel_link, logo) => {
  const response = await sendData("create_team", {name : name, coins_count : 0, channel_link : channel_link, logo : logo});
  return response;
};



export const getUserInfo = async () => {
  const response = await fetchData("initialize_user" + "?user_id=" + USER_ID)  
  return response;
};

export const getUserUpgrades = async () => {
  const response = await fetchData("get_user_upgrades" + "?user_id=" + USER_ID)
  return response;
};

export const getUserSpecials = async () => {
  const response = await fetchData("get_user_boosts" + "?user_id=" + USER_ID)
  return response;
}

export const getUserTasks = async () => {
  const response = await fetchData("get_user_tasks" + "?user_id=" + USER_ID)
  return response;
}

export const getUserRefferedCount = async () => {
  const response = await fetchData("friends_reffered_count" + "?user_id=" + USER_ID)
  return response;
}

export const getUserFriends = async () => {
  const response = await fetchData("friends_list" + "?user_id=" + USER_ID)
  return response;
}

export const getSearchTeams = async (searchQuery) => {
  const response = await fetchData(`get_teams?search_query=${searchQuery}`);
  return response;
};

export const getTeam = async (team_id) => {
  const response = await fetchData(`get_team?team_id=${team_id}`);
  return response;
};

export const getTopTeams = async () => {
  const response = await fetchData(`get_top5_teams?limit=25`);
  return response;
};

export const getPlayerPet = async () => {
  const response = await fetchData("get_player_pet" + "?user_id=" + USER_ID)
  return response;
}
export const getAllPets= async () => {
  const response = await fetchData("get_all_pets")
  return response;
}
export const getPet= async (petId) => {
  const response = await fetchData("get_pet" + "?pet_id=" + petId)
  return response;
}
export const getPlayerFood = async () => {
  const response = await fetchData("get_player_food" + "?user_id=" + USER_ID)
  return response;
}
export const getPlayerMemes = async () => {
  const response = await fetchData("get_player_memes" + "?user_id=" + USER_ID)
  return response;
}
export const getAllLeagues = async () => {
  const response = await fetchData("get_all_leagues")
  return response;
}
export const getLeague = async (time_period,league) => {
  const response = await fetchData("get_league"+ "?time_period=" + time_period +"&league="+league)
  return response;
}
export const getPalyerEnergy = async () => {
  const response = await fetchData("get_player_energy"+ "?user_id="+USER_ID)
  return response;
}
export const getFriendsList = async () => {
  const response = await fetchData("friends_list"+ "?user_id="+USER_ID)
  return response;
}
export const getPhoto = async () => {
  const response = await fetchData("get_photo"+ "?user_id="+USER_ID)
  return response;
}
