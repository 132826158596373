import React, { useState } from 'react';
import { useAppData } from '../../AppContext';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import "./boost.scss"
import battery from "./battery.png"
import coin from "../../coin.png"
export const Boost = () => {
    const { coins, upgrades, specials, handleEditCoins, purchaiseUpgrade, activateSpecial, dollar_coin } = useAppData();
    const [selectedUpgrade, setSelectedUpgrade] = useState(null);
    const navigate = useNavigate();

    const handleUpgradeClick = (upgrade) => {
        console.log(upgrade)
        setSelectedUpgrade(upgrade);
    };

    const handleCloseModal = () => {
        setSelectedUpgrade(null);
    };

    const handleActivateSpecial = async (specialId) => {
        await activateSpecial(specialId);
        navigate('/home');
    };

    const handleUpgrade = () => {
        if (selectedUpgrade) {
            const upgradeCost = getUpgradeCost(selectedUpgrade.level);
            if (coins >= upgradeCost) {
                handleEditCoins(-upgradeCost);
                console.log(selectedUpgrade.id)
                purchaiseUpgrade(selectedUpgrade.id);
            }
        }
        setSelectedUpgrade(null);

    };

    const upgradeCostList = [
        10000, 20000, 40000, 80000, 160000, 320000, 640000, 1280000, 2560000, 5120000, 10240000, 20480000, 40960000, 81920000, 163840000
    ];


    const formatNumber = (num) => {
        if (num >= 1000000) {
            return (num / 1000000).toFixed(1) + 'M';
        } else if (num >= 1000) {
            return (num / 1000).toFixed(1) + 'K';
        }
        return num;
    };

    const getUpgradeCost = (upgradeLevel) => {
        const adjustedLevel = upgradeLevel - 1;
        if (adjustedLevel >= 0 && adjustedLevel < upgradeCostList.length) {
            return upgradeCostList[adjustedLevel];
        } else {
            return null;
        }
    };


    return (



        <div className='outer-container_boost'>
            <div className='container_glow'>
                <div className="tab-header">
                    <div className="tab-header-background shop-header-background">
                    </div>
                    <div className='rocket_boost'>🚀</div>

                </div>
                <div className="tab-section-title">Free daily boosts</div>
                <div className="shop-spcial-container">
                    {specials.map((special) => (
                        <div key={special.id} className="tab-block" onClick={() => handleActivateSpecial(special.id)}>
                            <div className='d-flex' style={{ gap: "10px" }}>
                                <img src={getSpecialImage(special.id)} className='boost_img'></img>
                                <div>
                                    <p className="shop-special-name">{special.id}</p>
                                    <div id={`shop-special-count-${special.id}`} className="shop-special-count"><span>{special.count}</span> available</div>

                                </div>
                            </div>
                        </div>
                    ))}
                </div>
                <div className="tab-block" style={{ marginTop: "20px" }}>
                    {upgrades.map((upgrade) => (
                        <div key={upgrade.id} className="tab-subblock" onClick={() => handleUpgradeClick(upgrade)}>

                            <div className="shop-upgrade-img-case">
                                {getUpgradeInfo(upgrade.id).icon}
                            </div>
                            <div>
                                <div className="shop-upgrade-name">{getUpgradeInfo(upgrade.id).title}</div>
                                <div className="shop-upgrade-description">
                                    {upgrade.level != null ? (
                                        <div>
                                            <span className='row'>
                                                {formatNumber(getUpgradeCost(upgrade.level))} <img style={{ width: "20px" }} src={coin}></img>{` / ${upgrade.level} lvl`}
                                            </span>
                                        </div>
                                    ) : (
                                        <div>Max Level Reached</div>
                                    )}
                                </div>
                            </div>
                            {getUpgradeCost(upgrade.level) !== null && (
                                <button className="tab-button-arrow">›</button>
                            )}
                        </div>
                    ))}
                </div>



            </div>
            <Back />
            {selectedUpgrade && (
                <div className="modal">
                    <div className='container_glow_modal'>
                        <div className="modal-container">
                            <span className="close" onClick={handleCloseModal}>&times;</span>
                            <div className="modal-content">

                                <div className="modal-info">
                                    <div className="modal-image">
                                        {getUpgradeInfo(selectedUpgrade.id).icon}
                                    </div>
                                    <h2 className='modal-title'>{getUpgradeInfo(selectedUpgrade.id).title}</h2>
                                    <p>{getUpgradeInfo(selectedUpgrade.id).description}</p>
                                    <p>{getUpgradeInfo(selectedUpgrade.id).effect}</p>
                                    {selectedUpgrade.level !== null && (
                                        <button className='button-long' onClick={handleUpgrade}>BUY 🫰</button>
                                    )}
                                </div>

                            </div>
                        </div>
                    </div>
                </div>

            )}
        </div>

    );
};

const getSpecialImage = (name) => {
    switch (name) {
        case "rocket":
            return "https://nikicoin-bucket.s3.eu-north-1.amazonaws.com/images/other/rocket.png";
        case "full_energy":
            return battery;
        default:
            return null;
    }
};

const getUpgradeInfo = (name) => {
    switch (name) {
        case "multitap":
            return {
                name: "Multitap",
                icon: "☝️",
                title: "Multitap - Fastest hand in the Wild West✌️",
                description: "Increase amount of NIKI coins you can earn per one tap",
                effect: "+1/per tap each level"
            };
        case "rechargingSpeed":
            return {
                name: "Recharging Speed",
                icon: "⚡️",
                title: "️Recharging speed - Your girl dreams about it😏  ",
                description: "Recharging speed Increase the energy recharging speed",
                effect: "+1/sec for each level"
            };
        case "energyLimit":
            return {
                name: "Energy Limit",
                icon: "🔋",
                title: "Energy limit - Not coffee🍫",
                description: " Increase your energy limit, more mine per session",
                effect: "+1000🔋 points for each level"
            };
        default:
            return null;
    }
};
