import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import PlayOnPhone from './pages/PlayOnPhone';

const root = ReactDOM.createRoot(document.getElementById('root'));


if (window.tg && !window.tg.isExpanded) {
    window.tg.expand();
}
    let platform = window.Telegram.WebApp.platform;

    if (platform != 'ios'&& platform != 'android') {
           root.render(
                <PlayOnPhone />
            ); 
        } else {
            root.render(
                <App />
            );
     }
root.render(
    <App />
);



