import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { getTeam } from '../../http';
import { useAppData } from '../../AppContext';

import coinImg from '../../coin.png';
import defaultLogoImg from '../../images/teams/teamlogo_1.jpg';
import Back from '../Back';

export const Team = () => {
    const { teamId } = useParams();
    const [team, setTeam] = useState(null);
    const { user, joinTeam, exitTeam } = useAppData();

    useEffect(() => {
        const fetchTeam = async () => {
            const fetchedTeam = await getTeam(teamId);
            if (fetchedTeam) {
                setTeam(fetchedTeam);
            }
        };
        fetchTeam();
    }, [teamId]);

    const handleExitTeam = () => {
        exitTeam();
    };

    const handleJoinTeam = () => {
        joinTeam(teamId);
    };

    return (
        <div className='tab team-tab'>
            <Back />
            {team ? (
                <div className='team_background_observe'>
                    <div className="header_team">
                        <div className="team-info">
                            <img className='team-logo' src={team.logo ? team.logo : defaultLogoImg} alt={team.name} />
                            <h1 className='team-title'>{team.name}</h1>
                            <div className="team-details">
                                <div className='team-details-case'>
                                    <p>Total score</p>
                                    <h2 className='d-flex'>
                                        <img src={coinImg} className="coin-icon" alt="Coin icon" /> {team.total_score}
                                    </h2>
                                </div>
                                <div className='team-details-case'>
                                    <p>Participants</p>
                                    <h2>{team.users ? team.users.length : 0}</h2>
                                </div>
                            </div>
                        </div>
                    </div>



                </div>
            ) : (
                <p>Loading...</p>
            )}

            <div className='outer-container_team_observe'>
                <div className='container_glow_team_observe'>
                    {user.team === teamId ? (
                        <button className='button-long button-orange' onClick={handleExitTeam}>Exit team</button>
                    ) : (
                        <button className='button_team' onClick={handleJoinTeam}>{user.team ? 'Change team' : 'Join team'}</button>
                    )}

                    {team ? (
                        <div className='users_container' >
                            {team.users.map((user, index) => (
                                <div className='team_user' key={index}>
                                    <span>{user.name}</span>
                                    <span className='d-flex'>
                                        {user.total_coins_earned}
                                        <img src={coinImg} className="coin-icon" alt="Coin icon" />
                                    </span>
                                </div>
                            ))}


                        </div>
                    ) : (
                        <p>Loading...</p>
                    )}

                </div>
            </div>
        </div>
    );
};
