import React from 'react';
import test from "./test.png";
import coin from "../../coin.png"
function Task({ task, coins, icon, status, onClick }) {

    return (
        <div className={"tasks_con" + (status === "CM" ? " disabled" : "")} onClick={onClick}>
            <div className="iconWrapper " >
                <img src={icon} className='img_friends' alt="Task Icon" />
            </div>
            <div className="infoWrapper">
                <h2 className="name">{task}</h2>
                {status == "CM" ? (
                    <svg width="20" height="20" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.136364 19.4773L6.54545 13.0455L17.0682 23.3864L40.4091 0.136364L46.8636 6.56818L17.0682 36.2045L0.136364 19.4773Z" fill="white" />
                    </svg>
                ) : (
                    <span className="coins_reward">{coins} <img src={coin} className="coin_icon_task" alt="Coin-icon" /></span>
                )}
            </div>
        </div >
    );
}

export default Task;
