import React from 'react'
import coin from "../../coin.png"
function Friends_task({ task, coins, emoji, color, league, isCompleted }) {
    return (
        <div className={`frieds_task ${color}`}>
            <div className="iconWrapper img_friends">
                {emoji}
            </div>
            <div className="infoWrapper">
                <h2 className="name">{task}</h2>
                {isCompleted ?
                    <svg width="20" height="20" viewBox="0 0 47 37" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M0.136364 19.4773L6.54545 13.0455L17.0682 23.3864L40.4091 0.136364L46.8636 6.56818L17.0682 36.2045L0.136364 19.4773Z" fill="white" />
                    </svg>
                    :
                    <div className='flex-container '>
                        <span className="coins flex_center">+{coins}<img src={coin} className="coin_icon_task" alt="Coin-icon" /></span>
                        {league && <span className="league">+{league}</span>}
                    </div>
                }


            </div>
        </div>
    );
}


export default Friends_task