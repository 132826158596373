import React, { useState, useEffect } from 'react';
import "./league.scss";
import Back from '../Back';
import { useAppData } from '../../AppContext';
import arrow from "./arrow.png";
import { useParams, useNavigate } from 'react-router-dom';
import { getLeague } from '../../http';
// import coinImg from '../../images/icons/coin.png';
import coinImg from "../../coin.png"

function League() {
    const { leagues, total_coins_earned, refferedCount } = useAppData();
    const { leagueId } = useParams();
    const navigate = useNavigate();
    const [selected, setSelected] = useState('day');
    const [leagueUsers, setLeagueUsers] = useState([]);
    const [currentLeagueIndex, setCurrentLeagueIndex] = useState(0);

    useEffect(() => {
        const index = leagues.findIndex(league => league.id === parseInt(leagueId));
        if (index !== -1) {
            setCurrentLeagueIndex(index);
        } else {
            navigate('/league/not-found');
        }
    }, [leagueId, leagues, navigate]);

    const getLeaguePlayers = async () => {
        const data = await getLeague(selected, leagueId);
        setLeagueUsers(data.league);
    }

    useEffect(() => {
        getLeaguePlayers();
    }, []);
    useEffect(() => {
        getLeaguePlayers();
    }, [currentLeagueIndex, selected]);

    const leagueColors = ["#7794CC", "#D7AF22", "#1AADDC"];

    const nextLeague = () => {
        const nextIndex = currentLeagueIndex + 1 >= leagues.length ? 0 : currentLeagueIndex + 1;
        navigate(`/league/${leagues[nextIndex].id}`);
        setSelected("day");
    };

    const prevLeague = () => {
        const prevIndex = currentLeagueIndex - 1 < 0 ? leagues.length - 1 : currentLeagueIndex - 1;
        navigate(`/league/${leagues[prevIndex].id}`);
        setSelected("day");
    };

    const getProgressWidth = () => {
        if (leagues[currentLeagueIndex].name === "Friends") {
            return `${(refferedCount / 3) * 100}%`;
        }
        return total_coins_earned > leagues[currentLeagueIndex].coin_limit
            ? "100%"
            : `${Math.min(100, (total_coins_earned / leagues[currentLeagueIndex].coin_limit) * 100)}%`;
    };

    function handleBack() {
        navigate("/");
    }

    useEffect(() => {
        if (window.Telegram?.WebApp) {
            window.Telegram.WebApp.BackButton.show();
            window.Telegram.WebApp.BackButton.onClick(handleBack);
        }
        return () => {
            if (window.Telegram?.WebApp) {
                window.Telegram.WebApp.BackButton.offClick(handleBack);
            }
        };
    }, [navigate]);

    return (
        <div className='league_container' style={{ backgroundColor: leagueColors[currentLeagueIndex % leagueColors.length] }}>
            <div className='league_back'>
                <div className="header_league">
                    <div className="header_league_background"></div>
                    <img className='logo_league' src={leagues[currentLeagueIndex].logo} alt="League Logo"></img>
                    <img onClick={prevLeague} src={arrow} className='arrow_left arrow' alt="Previous"></img>
                    <img onClick={nextLeague} className="arrow" src={arrow} alt="Next"></img>
                    <div className='coins_containers'>
                        <div className='league_name'>{leagues[currentLeagueIndex].name} League</div>

                        <div className='coins_league'>
                            {leagues[currentLeagueIndex].name === "Friends" ? `${(refferedCount + " / 3 friends")}` : `${"🌕" + total_coins_earned.toLocaleString('en-US') + " / " + leagues[currentLeagueIndex].coin_limit.toLocaleString('en-US')}`}
                        </div>
                        <div className="progress_bar_league">
                            <div className="progress_league" style={{ width: getProgressWidth() }}></div>
                        </div>
                    </div>
                </div>
            </div>
            <div className='outer-container_league'>
                <div className='container_glow_league'>
                    <div className='range_selector'>
                        <div className={selected === "day" ? "range selected_league" : "range"} onClick={() => setSelected("day")}> Day</div>
                        <div className={selected === "week" ? "range selected_league " : "range"} onClick={() => setSelected("week")}> Week</div>
                    </div>

                    {leagueUsers.length ? (
                        <div className='users_container'>
                            {leagueUsers.map((user, index) => (
                                <div className='team_user' key={index}>
                                    <span>{user.name}</span>
                                    <span className='d-flex'>
                                        {user.total_earned}
                                        <img src={coinImg} className="coin-icon" alt="Coin icon" />
                                    </span>
                                </div>
                            ))}
                        </div>
                    ) : (
                        <div className='no_users'>No users </div>
                    )}
                </div>
            </div>
        </div>
    );
}

export default League;
