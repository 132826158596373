import React, { useEffect, useRef, useState } from 'react';
import { useAppData } from '../AppContext';
import specialRocketImg from '../images/specials/rocket.png';
import specialBombImg from '../images/specials/bomb.png';
import specialShurikenImg from '../images/specials/shuriken.png';
import "./home.scss";
import rocket from "./rocket.png";
import StatBlock from '../Components/StatsMain/StatBlock';
import Footer from '../Components/Footer/Footer';
import energy_img from "./enegry.png"
import { Link } from 'react-router-dom';
import { sendCoinsAndEnergy, getPalyerEnergy } from '../http';
import { useNavigate } from 'react-router-dom';
import noimage from "./noimage.webp"
import play_coin from "./coin1.png"

export const Home = () => {
    const navigate = useNavigate();

    const coinImage = useRef(null);
    const energyBar = useRef(null);
    const specialBackground = useRef(null);
    const { energy, coins, energyLimit, handleEditEnergy, clickCost, user, specialMode, total_coins_earned, handleEditCoinsClick, userPhoto, energyRechargeDelay, setEnergy } = useAppData();
    const [interactionActive, setInteractionActive] = useState(false);
    const [username, setUsername] = useState("");
    const [leagueInfo, setLeagueInfo] = useState({ name: '', color: '' });
    const leagues = [
        { name: '🎉 Friend', color: '#C7C7C7', threshold: 0 },
        { name: '🥈 Silver', color: '#91B3F8', threshold: 300000 },
        { name: '🥇 Gold', color: '#F3CD46', threshold: 1000000 },
        { name: '💎 Diamond', color: '#37CDEE', threshold: 10000000 },
        { name: '⭐ Epic', color: '#C019CD', threshold: 50000000 },
        { name: '🏅 Legendary', color: '#3940E2', threshold: 100000000 },
        { name: '🏆 Master', color: '#E73232', threshold: 1000000000 },
    ];

    const getEnergyData = async () => {
        const data = await getPalyerEnergy();
        setEnergy(data.energy_count);
    };

    useEffect(() => {
        getEnergyData();
    }, []);

    useEffect(() => {
        const sortedLeagues = [...leagues].sort((a, b) => a.threshold - b.threshold);
        const currentLeague = sortedLeagues.find(league => total_coins_earned < league.threshold);
        if (currentLeague) {
            setLeagueInfo({ name: currentLeague.name, color: currentLeague.color });
        } else {
            const highestLeague = sortedLeagues[sortedLeagues.length - 1];
            setLeagueInfo({ name: highestLeague.name, color: highestLeague.color });
        }
    }, [total_coins_earned]);

    const handleTouchStart = (event) => {
        Array.from(event.touches).forEach(touch => {
            earnCoins(clickCost, touch);
        });
    };

    function earnCoins(income, event) {
        if (specialMode) {
            let randomFactorOptions = [0.5, 1, 1.5];
            let randomFactor = randomFactorOptions[Math.floor(Math.random() * randomFactorOptions.length)];
            income += specialMode.bonus * randomFactor;
        } else {
            income = Math.min(income, energy);
        }

        if (income > 0) {
            handleEditCoinsClick(income);
            showIncome(income, event.clientX, event.clientY);
            if (!specialMode) {
                handleEditEnergy(-1);
            }
        }
    }

    const specialData = {
        "rocket": {
            spanImage: `url(${specialRocketImg})`,
            background: 'rgba(255, 150, 26, 1)'
        },
        "bomb": {
            spanImage: `url(${specialBombImg})`,
            background: 'rgba(39, 151, 255, 1)'
        },
        "shuriken": {
            spanImage: `url(${specialShurikenImg})`,
            background: 'rgba(167, 25, 255, 1)'
        }
    };

    function showIncome(income, clientX, clientY) {
        const incomeElement = document.createElement('div');
        incomeElement.innerText = '+' + income;
        incomeElement.classList.add('income');
        incomeElement.style.left = `${clientX}px`;
        incomeElement.style.top = `${clientY - 24}px`;
        document.body.appendChild(incomeElement);

        setTimeout(() => {
            incomeElement.style.top = `${clientY - 128}px`;
        }, 10);

        setTimeout(() => {
            incomeElement.style.opacity = "0";
        }, 250);

        setTimeout(() => {
            incomeElement.remove();
        }, 1000);
    }

    useEffect(() => {
        if (energyBar.current) {
            energyBar.current.style.width = `${(energy / energyLimit) * 100}%`;
        }
    }, [energy, energyLimit]);

    const handleClick = () => {
        setIsClicked(true);
        setTimeout(() => setIsClicked(false), 100);
    };
    const [isClicked, setIsClicked] = useState(false);

    useEffect(() => {
        if (specialMode) {
            const specialInfo = specialData[specialMode.mode];
            specialBackground.current.style.display = 'block';
            specialBackground.current.style.opacity = 1;
            const specialBackgroundElement = specialBackground.current.querySelector('.special-background');
            const specialSpans = specialBackground.current.querySelectorAll('.special-background span');
            specialBackgroundElement.style.background = `radial-gradient(circle at center bottom, ${specialInfo.background}, rgb(12, 12, 12) 75%)`;
            specialSpans.forEach(span => {
                span.style.backgroundImage = specialInfo.spanImage;
            });
        } else {
            specialBackground.current.style.opacity = 0;
            specialBackground.current.addEventListener('transitionend', function () {
                specialBackground.current.style.display = 'none';
            }, { once: true });
        }
    }, [specialMode]);

    const handleInteraction = (event) => {
        event.preventDefault();

        if (interactionActive) return;
        setInteractionActive(true);

        if (event.type === 'touchstart') {
            Array.from(event.touches).forEach(touch => {
                applyTransformation(touch.clientX, touch.clientY);
                earnCoins(clickCost, touch);
            });
        } else if (event.type === 'mousedown') {
            applyTransformation(event.clientX, event.clientY);
            earnCoins(clickCost, event);
        }
        setTimeout(() => {
            setInteractionActive(false);
        }, 300);
    };

    const applyTransformation = (clientX, clientY) => {
        const containerRect = coinImage.current.getBoundingClientRect();
        const centerX = (clientX - containerRect.left) / containerRect.width * 2 - 1;
        const centerY = (clientY - containerRect.top) / containerRect.height * 2 - 1;
        const rotateX = -centerY * 15;
        const rotateY = centerX * 15;
        coinImage.current.style.transform = `perspective(1000px) rotateX(${rotateX}deg) rotateY(${rotateY}deg) scale3d(0.98, 0.98, 0.98)`;
    };

    function openTelegramLink() {
        if (window.Telegram.WebApp) {
            window.Telegram.WebApp.openTelegramLink('https://t.me/DoosCoin');
        } else {
        }
    }

    useEffect(() => {
        if (window.Telegram && window.Telegram.WebApp) {
            if (window.Telegram.WebApp.BackButton && window.Telegram.WebApp.BackButton.isVisible) {
                window.Telegram.WebApp.BackButton.hide();
            }
            const webAppUser = window.Telegram.WebApp.initDataUnsafe.user;
            if (webAppUser) {
                setUsername(webAppUser.first_name);
            }
        }
    }, [setUsername]);

    const coinsRef = useRef(coins);
    const energyRef = useRef(energy);

    useEffect(() => {
        const energyUpdateInterval = setInterval(() => {
            sendCoinsAndEnergy(coinsRef.current, energyRef.current);
        }, 5000);
        return () => clearInterval(energyUpdateInterval);
    }, []);

    useEffect(() => {
        energyRef.current = energy;
    }, [energy]);

    useEffect(() => {
        coinsRef.current = coins;
    }, [coins]);

    useEffect(() => {
        const energyUpdateInterval = setInterval(() => {
            setEnergy(prevEnergy => {
                if (prevEnergy < energyLimit) {
                    return Math.min(prevEnergy + energyRechargeDelay, energyLimit);
                }
                return prevEnergy;
            });
        }, 1000);
        return () => clearInterval(energyUpdateInterval);
    }, [energyLimit, energyRechargeDelay]);

    useEffect(() => {
        const handleBeforeUnload = () => {
            sendCoinsAndEnergy(coinsRef.current, energyRef.current);
        };

        window.addEventListener("beforeunload", handleBeforeUnload);

        return () => {
            window.removeEventListener("beforeunload", handleBeforeUnload);
        };
    }, []);

    return (
        <div style={{ height: "100vh", overflow: "hidden" }}>
            <div className="container">
                {user && user.team ? (
                    <div className="header">
                        <Link to={`/team/${user.team.id}`} className="player-team-info">
                            <img src={user.team.logo} alt="Team logo" className="player-team-logo" draggable="false" />
                            <div>
                                <div className="player-name">{user.team.name}</div>
                                <div className="player-team-stats">
                                    <div className="player-team-coins">
                                        <img src={play_coin} className="coin-icon" alt="Coin icon" />{user.team.total}
                                    </div>
                                    <div className="player-team-league">🥇 Gold League</div>
                                </div>
                            </div>
                        </Link>
                        <div className='settings'>
                            <img src={userPhoto ? userPhoto : noimage}
                                className='setting_logo' alt="User Photo" />
                        </div>
                    </div>
                ) : (
                    <div className="header">
                        <Link to="/team-explorer" className="player-team-info player-team-join">
                            <span>Join team  ›</span>
                        </Link>
                        <div className='settings'>
                            <img src={userPhoto ? userPhoto : noimage}
                                className='setting_logo' alt="User Photo" />
                        </div>
                    </div>
                )}
                <div className='outer-container_home'>
                    <div className='container_glow_home'>
                        <div className="effect-background" ref={specialBackground}>
                            <div className="special-background">
                                <span style={{ '--i': 16 }}></span>
                                <span style={{ '--i': 11 }}></span>
                                <span style={{ '--i': 19 }}></span>
                                <span style={{ '--i': 13 }}></span>
                                <span style={{ '--i': 15 }}></span>
                                <span style={{ '--i': 20 }}></span>
                                <span style={{ '--i': 12 }}></span>
                                <span style={{ '--i': 17 }}></span>
                            </div>
                        </div>
                        <div className="main-content">
                            <div className='column'>
                                <StatBlock />
                                <div className='row_home'>
                                    <div className="stats">
                                        <img src={play_coin} className="stats-coin" alt="Coin icon" />
                                        <div id="balance" className="coin-count">{coins.toLocaleString('en-US')}</div>
                                    </div>
                                    <div className='tg_btn' onClick={openTelegramLink}><div>📣</div>Tg</div>
                                </div>
                            </div>
                            <div id="playZone" className="play-zone">
                                <div className="gameplay-coin-glow" style={{ background: `radial-gradient(${leagueInfo.color}, rgb(255 255 255 / 0%) 66%)` }}></div>
                                <div className="gameplay-coin" onTouchStart={handleTouchStart} ref={coinImage}>
                                    <img
                                        src={play_coin}
                                        alt="Coin"
                                        draggable="false"
                                        onTouchStart={handleTouchStart}
                                        onPointerDown={handleInteraction}
                                        onClick={handleClick}
                                        style={{
                                            transition: 'transform 0.1s ease-out',
                                            transform: isClicked ? 'scale(0.98)' : 'scale(1)',
                                        }}
                                    />
                                </div>
                            </div>
                            <div className='column'>
                                <div onClick={() => navigate(`/league/${user.league.id}`)} className="league_btn">
                                    <span className="league_text">{user.league.name} League</span>
                                    <svg width="24" height="15" viewBox="0 0 24 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 45L21 24L3 3" stroke="white" strokeWidth="5" strokeLinecap="round" strokeLinejoin="round" />
                                    </svg>
                                </div>
                                <div className="energy-wrapper">
                                    <img src={energy_img} className="energy-icon" alt="Energy icon" />
                                    <div className="energy-counter">{energy}</div>
                                    <div className="energy-bar">
                                        <div className="energy" ref={energyBar}></div>
                                    </div>
                                    <Link to="/boosts" className="boost_btn">
                                        <img src={rocket} className="boost_btn_img" alt="Boost button" />
                                        Boost
                                    </Link>
                                </div>
                            </div>
                        </div>
                        <Footer />
                    </div>
                </div>
            </div>
        </div>
    );
};
