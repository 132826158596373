import React from 'react';
import { useAppData } from '../../AppContext';

import giftImg from '../../images/icons/gift.png';
import coinImg from '../../images/icons/coin.png';
import premiumImg from '../../images/icons/premium.png';
import { useNavigate } from 'react-router-dom';
import Back from '../Back';
import Friends_task from './Friends_task';
import "./friends.scss";
import { SendInvite } from '../../http';
import coin from "../../coin.png"

export const Frens = () => {
    const navigate = useNavigate();

    const { refferedCount, TotalEarndFromFriends } = useAppData();
    const friendsTasks = [
        { id: 1, task: "Add 3 friends", friendsRequired: 3, coins: 50000, emoji: "🎉", color: "purple", league: null },
        { id: 2, task: "Add 10 friends", friendsRequired: 10, coins: 100000, emoji: "🥉", color: "orange", league: null },
        { id: 3, task: "Add 50 friends", friendsRequired: 50, coins: 500000, emoji: "🥈", color: "silver", league: null },
        { id: 4, task: "Add 100 friends", friendsRequired: 100, coins: 1000000, emoji: "🥇", color: "gold", league: null },
        { id: 5, task: "Add 500 friends", friendsRequired: 500, coins: 10000000, emoji: "💎", color: "blue", league: "Diamond League" },
        { id: 6, task: "Add 1000 friends", friendsRequired: 1000, coins: 100000000, emoji: "🏆", color: "yellow", league: "Legendary League" }
    ];

    const formatNumber = (num) => num >= 1000000 ? (num / 1000000).toFixed() + 'M' : num >= 1000 ? (num / 1000).toFixed() + 'K' : num.toString();
    const handleCLick = () => {
        SendInvite();
        window.Telegram.WebApp.close();
    };

    return (
        <div className='outer-container_boost'>
            <div className='container_glow_friends'>
                <Back />
                <div className="tab-header">
                    <div className="tab-header-background frens-header-background"></div>
                    <img src={giftImg} className="tab-img" draggable="false" />
                    <h2 className="tab-title">FRIENDS</h2>
                </div>
                <div className="tab_block_friends background_block row" onClick={() => navigate("/friendsList")} >
                    <div className="frens-coins-earned flex_center">+{formatNumber(TotalEarndFromFriends)}<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                    <div>{refferedCount} Friends added</div>
                    <button className="tab-button-arrow">›</button>
                </div>

                <div className="tab_block_friends background_block">
                    <div className="tab-subblock">
                        <img src={coin} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title name">Add a friend:</div>
                            <div className='row'>
                                <div className='flex_center'>+5,000 You<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                                <div className='ml-10 flex_center'> +5,000 Friend<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                            </div>

                        </div>
                    </div>
                    <div className="tab-subblock">
                        <img src={premiumImg} className="frens-mainblock-img" />
                        <div>
                            <div className="tab-block-title">Add a friend with Telegram premium:</div>
                            <div className='row'>
                                <div className='flex_center'>+25,000 You<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                                <div className='ml-10 flex_center'> +25,000 Friend<img src={coin} className="coin_icon_task" alt="Coin-icon" /></div>
                            </div>
                        </div>
                    </div>
                    <button className="button_invite button-purplr" onClick={() => handleCLick()}>Invite a friend👥</button>
                </div>
                <div className='friends_grid'>
                    {friendsTasks.map(task => (
                        <Friends_task
                            key={task.id}
                            task={task.task}
                            coins={formatNumber(task.coins)}
                            emoji={task.emoji}
                            color={task.color}
                            league={task.league}
                            isCompleted={refferedCount >= task.friendsRequired}
                        />
                    ))}
                </div>
            </div>
        </div>
    );
}
