import React, { useEffect, useState } from 'react'
import { getFriendsList } from '../../http'
import giftImg from '../../images/icons/gift.png';
import coinImg from './coin.png';
import Back from '../Back';
function FriendsList() {

    const [friends, setFriends] = useState([]);

    useEffect(() => {
        const fetchTeam = async () => {
            const data = await getFriendsList();
            console.log(data)
            setFriends(data.friends)
        };
        fetchTeam();
    }, []);

    return (


        <div className='outer-container_boost'>
            <div className='container_glow_friends'>
                <Back />
                <div className="tab-header">
                    <div className="tab-header-background frens-header-background"></div>
                    <img src={giftImg} className="tab-img" draggable="false" />
                    <h2 className="tab-title">FRIENDS</h2>
                </div>

                {friends.length ? (
                    <div className='users_container'>
                        {friends.map((user, index) => (
                            <div className='team_user' key={index}>
                                <span>{user.name}</span>
                                <span className='d-flex'>
                                    {user.total_coins_earned}
                                    <img src={coinImg} className="coin-icon" alt="Coin icon" />
                                </span>
                            </div>
                        ))}
                    </div>
                ) : (
                    <p>You didn`t invite any of your friends</p>
                )}
            </div>
        </div>






    )
}

export default FriendsList